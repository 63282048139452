.wrapper-about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0%;
    background-color: black;
    color:white;
    .media-about{
       margin-top: 25vh;
        img{
            max-width: 50vw;
            max-height:60vh ;  
            @media (max-width:868px){
                max-height: 70vw;
                max-width: 90vw;
                margin-left: 5vw;
            }
        }
            @media (max-width:868px) {
                margin-top: 10vh;
            }    
    }   
    .text-about{
        width: 60%;
        height: auto;
        word-wrap: break-word;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 5%;
        margin-bottom: 5%;
        
        @media (max-width:868px){
            width: 90%;
            justify-content: center;
            color:white;
            margin-left: 2%;
        }
    }
    @media (max-width:868px) {
        margin-top: 0%;
    }
}