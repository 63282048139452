.contact-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    color: white;
    @media (max-width:868px) {
        justify-content: flex-start;
        align-items: flex-start;
        
    }

    .image-contact{
        display: flex;
        justify-content: center;
        margin-top: 12%;
        img{
            width: 50vw;
            height:60vh ;  
            @media (max-width:868px){
                height: 60vw;
                width: 80vw;
                margin-top: 20%;
                margin-left: 10vw; ;
            } 
        }
        @media (max-width:868px) {
            margin-top: 15%;
        }
    }
    .form-wrapper{
        .form-title{
            margin-bottom: 5%;
            font-size: 33px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}
.partita-iva{
    font-size:3vw;
    margin-right:10%;
    @media (max-width:868px) {
        font-size: 5vw;
    }
}