body{
  overflow-x: hidden;  
}
.servizio-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 12%;
    background-color: black;
    color:white;
    min-height: 100vh;
    @media (max-width:868px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
    .swiper-pagination-bullet-active{
        background: white;
        
    }
    .buttons-swiper{
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-top: 0%;
        @media (max-width:868px) {
                display: none;
        }
        .next-button{
            margin-left: 5%;

        }
        .prev-button,.next-button{
            font-size:1.7em;
            color: white;
            cursor: pointer;
        }
    }

    .title-services{
        width: 100vw;
        text-align: center;
        text-transform: uppercase;
        margin-top: 7%;
        padding-left: 0%;
    }
    .image-services{
        display: flex;
        justify-content: center;

        img{
            width: 50vw;
            height:60vh ; 

            @media (max-width:868px){
                height: 60vw;
                width: 80vw;
                margin-left: 10vw; ;
            } 
        }
        @media (max-width:868px) {
            margin-top: 15%;
        }
    }
    .wrapper-frase{
        width: 60%;
        height: auto;
        word-wrap: break-word;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 5%;
        margin-bottom: 5%;
        
        @media (max-width:868px){
            width: 90%;
            justify-content: center;
            color:white;
            margin-left: 2%;
        }
    }
    
    
    .swiper-wrapper-container{
        height: 70vh;
        width: 60vw;
        position: relative;
        left: 0;

        @media (max-width:868px){
            width: 100vw;
            height: 40vh;
            left: 0;
        }
        .swiper-container{
            @media (max-width:868px){
                width: 100vw;
                height: 40vh;
            }
            .swiper-wrapper{
                    height: 60vh;
                    @media (max-width:868px){
                        width: 100vw;
                        height: 40vh;
                    }
            .swiper-slide{
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                @media (max-width:868px){
                    width: 100vw;
                    height: 40vh;
                }
                img{
                    flex-shrink: 0;
                    max-width: 100%;
                    max-height: 100%;
                    @media (max-width:868px){
                        width: 100vw;
                        height: 40vh;
                    }
                }
            }
        }
            }

    
        }
}
