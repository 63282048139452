.avatar-home{

    display: flex;
    justify-content: center;
        min-height: 40%;
    @media (max-width:868px) {
        margin-top: -8vh;
    }
    .image{
        border-style: solid;
        border-color: black;
        border-width: 2px;
    }
    img{
        margin-top: 30%;
        height: 60vh;
        @media (max-width:868px) {
            height: 40vh;
        }
    }
}