
    .hero-wrapper
    {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        min-height: 100vh;
        overflow: hidden;

        @media (max-width:868px) {
            min-height: 50vh;

        }
        img, video{
            width: 100vw;
            min-height: 100vh;
            position: absolute;
            top:0;
            left: 0;
            @media (max-width:868px) {
                padding: 0% 2%;
                padding-top: 10%;
                min-height: 60vh;
            }
        }

    
    }

