.swiper-button-next{
    font-size: 25px;
    color: white;
    cursor: pointer;

/*    mix-blend-mode:exclusion;
*/}
.swiper-container{
    max-height: 100%;

}

.swiper-button-prev{
    font-size: 25px;
    color: white;
    cursor: pointer;
    /*  mix-blend-mode:exclusion
*/}
.swiper{
    height: 100vh;

}
.first-wrapper-container{
    height: 100vh;

}